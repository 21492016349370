<script lang="ts">
    import { inertia, Link } from '@inertiajs/svelte';
    import * as Table from '$lib/components/ui/table';

    interface Subscription {
        creator: {
            avatar: string;
            name: string;
        };
        subscriber?: {
            avatar: string;
            name: string;
        };
        status: 'canceled' | 'completed' | 'failed';
        provider: string;
        expires_at: string | null;
    }

    interface Data {
        subscribersCount: number;
        activeSubsTab: 'subscriptions' | 'subscribers';
        subscriptions: {
            data: Subscription[];
        };
        CANCELED_STATUS: string;
        ACTIVE_STATUS: string;
    }

    export let data: Data;

    function getBaseUrl(): string {
        const { protocol, hostname, port } = window.location;
        return `${protocol}//${hostname}${port ? `:${port}` : ''}`;
    }

    const options: Intl.DateTimeFormatOptions = {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
    };

    const dateFormatter = new Intl.DateTimeFormat('en-US', options);

    function getStatusBadge(status: string): string {
        const baseClasses = 'inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset';
        switch (status) {
            case 'canceled':
                return `${baseClasses} bg-yellow-50 text-yellow-800 ring-yellow-600/20`;
            case 'completed':
                return `${baseClasses} bg-green-50 text-green-700 ring-green-600/20`;
            case 'failed':
                return `${baseClasses} bg-red-50 text-red-700 ring-red-600/10`;
            default:
                return `${baseClasses} bg-gray-50 text-gray-600 ring-gray-500/10`;
        }
    }
</script>

{#if data.subscribersCount}
    <div class="mt-md-3 inline-border-tabs mb-1 mt-0">
        <nav class="nav nav-pills nav-justified">
            {#each ['subscriptions', 'subscribers'] as tab}
                <a
                    class="nav-item nav-link"
                    use:inertia
                    href="{getBaseUrl() +
                        (tab === 'subscriptions'
                            ? '/my/settings/subscriptions?active=subscriptions'
                            : '/my/settings/subscriptions?active=subscribers')}"
                    class:active="{data.activeSubsTab === tab}"
                >
                    <div class="d-flex align-items-center justify-content-center">
                        {tab}
                    </div>
                </a>
            {/each}
        </nav>
    </div>
{/if}

{#if data.subscriptions.data.length > 0}
    <Table.Root>
        <Table.Header>
            <Table.Row>
                <Table.Head class="w-1/4">{data.activeSubsTab == 'subscriptions' ? 'To' : 'From'}</Table.Head>
                <Table.Head class="w-1/6">Status</Table.Head>
                <Table.Head class="hidden w-1/6 md:table-cell">Paid with</Table.Head>
                <Table.Head class="w-1/6">Renews</Table.Head>
                <Table.Head class="hidden w-1/6 md:table-cell">Expires at</Table.Head>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {#each data.subscriptions.data as subscription}
                <Table.Row>
                    <Table.Cell class="flex items-center">
                        <div class="mr-2 h-8 w-8">
                            <img
                                src="{data.activeSubsTab == 'subscriptions'
                                    ? subscription.creator.avatar
                                    : subscription.subscriber?.avatar}"
                                alt="{data.activeSubsTab == 'subscriptions'
                                    ? subscription.creator.name
                                    : subscription.subscriber?.name}"
                            />
                        </div>
                        <Link href="#">
                            {data.activeSubsTab == 'subscriptions'
                                ? subscription.creator.name
                                : subscription.subscriber?.name}
                        </Link>
                    </Table.Cell>
                    <Table.Cell>
                        <span class="{getStatusBadge(subscription.status)}">
                            {subscription.status.charAt(0).toUpperCase() + subscription.status.slice(1)}
                        </span>
                    </Table.Cell>
                    <Table.Cell class="hidden md:table-cell">
                        {subscription.provider.charAt(0).toUpperCase() + subscription.provider.slice(1)}
                    </Table.Cell>
                    <Table.Cell>
                        {subscription.expires_at
                            ? subscription.status == data.CANCELED_STATUS
                                ? '-'
                                : dateFormatter.format(new Date(subscription.expires_at))
                            : '-'}
                    </Table.Cell>
                    <Table.Cell class="hidden md:table-cell">
                        {subscription.expires_at
                            ? subscription.status == data.ACTIVE_STATUS
                                ? '-'
                                : dateFormatter.format(new Date(subscription.expires_at))
                            : '-'}
                    </Table.Cell>
                </Table.Row>
            {/each}
        </Table.Body>
    </Table.Root>
{:else}
    <div class="px-4 py-3">
        <p>There are no active or cancelled subscriptions at the moment.</p>
    </div>
{/if}
